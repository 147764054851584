html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  /* font-family: "Noto Serif JP", serif; */
  font-family:Arial,"Arial Unicode MS", sans-serif;
  font-size: 1.5vh;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  /* font-family: "Noto Serif JP", serif; */
}

#root{
  width: 100%;
  height: 100%;
}

#rootContainer {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  column-gap: 1vw;
  /* row-gap: 1vh; */
  width: 100%;
  height: 100%;
}

.mainImg {
  width: 100%;
    /* height: auto; */
    max-height: 97vh;
}

.mainVideo{
  height: 96vh;
}

.workImg,
.workVideo {
  margin-top: 1vmax;
  max-width: 100%;
  max-height: 96vh;
  object-fit: contain;
}

.mainVideoVertical {
  max-width: 50%;
  max-height: 96vh;
  height: auto;
}

.mainImgVertical {
  height: 96vh;
  /* height: auto; */
}

.workLinks {
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  padding: 1vmax;
}

#footer {
  position: absolute;
  bottom: 0.25vh;
  left: 1vmax;
  z-index: -1;
  font-size: 0.75rem;
  /* color: gray; */
}

@media screen and (max-width: 768px) {
  html,
body {
  /* font-size: 10px; */
  font-size: 1.8vh;
}
  .mainVideoVertical, .mainVideo{
    max-width: 100%;
    height: auto;
  }

  .mainImgVertical{
    max-width: 100%;
    height: auto;
  }
  
}