.container {
  grid-row: 1;
  display: grid;
  grid-template-columns: max(60px, 15vw) auto;
  grid-template-rows: 1vmax auto;
  column-gap: 1vw;
  row-gap: 1vh;
  margin-left: 1.5vmax;
  margin-bottom: 1.5vmax;
  margin-top: 1vmax;
  /* display: flex;
  flex-direction: row;
  margin-left: 1.5vmax;
  margin-bottom: 1.5vmax;
  margin-top: 0.5vmax;
  width: 100%; */
}

.title {
  font-size: 1.2rem;
}

.titleActive {
  font-size: 1.2rem;
  font-weight: bold;
}

.description {
  font-size: 0.75rem;
}

.thumbnailsContainer {
  height: 100px;
}

/*

.thumbnailContainer {
  cursor: pointer;
  margin-bottom: 1.5vmax;
}


.thumbnailContainerActive {
  color: gray;
  cursor: pointer;
  margin-bottom: 1.5vmax;
} */

.thumbnailTag{
  font-size: 1rem;
  /* font-weight: bold; */
  /* color: green; */
}

.thumbnail{
  font-size: 1.2rem;
  margin: 1.1vh 0;
  cursor: pointer;

}

.thumbnailActive{
  font-size: 1.2rem;
  margin: 1.1vh 0;
  /* font-weight: bold; */
  text-decoration: underline;

}

.thumbnail:hover{
  font-size: 1.2rem;
  margin: 1.1vh 0;
  /* font-weight: bold; */
  text-decoration: underline;

}

.thumbnailContainer{
  margin-top: 1.5vmax;
}

.thumbnailsHeader{
  font-size: 1.6rem;
  white-space: nowrap;
    z-index: 1;
  /* font-weight: bold; */
  /* text-decoration: underline; */
  /* color: green; */
}

.spacer{
  display: inline-block;
  width: 0.4em;
}

.menuActive{
  text-decoration: underline;
  cursor: pointer;
}

.menuUnactive{
  text-decoration: none;
  cursor: pointer;
}

/* WORK DETAILS PAGE */

.overflow {
  overflow: auto;
  grid-column: 2;
  grid-row: 1 / span 2;
}

.overflowShow{
  grid-row: 2;
  overflow: auto;
  display: block;
}

.overflowHide{
  grid-row: 2;
  overflow: auto;
  display: none;
}

.overflow2 {
  overflow: auto;
  grid-column: 1;
  scrollbar-width: none;
}

.overflow2Show{
  grid-row: 2;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 1rem;
  overflow: auto;
  display: block;
}

.overflow2Hide{
  grid-row: 2;
  overflow: auto;
  display: none;
}

.workMainContainer {
  display: grid;
  grid-template-columns: 50% auto;
  grid-template-rows: auto;
  height: 100px;
  margin: 2vmax;
  padding-bottom: 1vmax;
  margin-top: 0vmax;
  /* width: 80vw; */
}

.writingMainContainer{
  display: grid;
  grid-template-columns: 60% auto;
  grid-template-rows: auto;
  height: 100px;
  margin: 2vmax;
  padding-bottom: 1vmax;
  margin-top: 0vmax;
}



.workImgMain {
  grid-column: 1 / span 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2vmax;
}

.workText {
  font-size: 1.2rem;
  /* text-align: justify; */
  padding-top: 1vmax;
  white-space: pre-line;
}

.workDesc {
  padding: 1vmax;
  white-space: pre-line;
  text-align: right;
  max-width: 80%;
  margin-left: auto;
  margin-right: 0;
}

.workDescLeft {
  padding: 1vmax;
  padding-bottom: 0vmax;
  white-space: pre-line;
  text-align: left;
  width: 60%;
  margin-left: auto;
  /* margin-right: 0; */
}

.workTextContainer {
  grid-column: 1;
  margin-right: 2vmax;
}
.writingTextContainer {
  grid-column: 1;
  margin-left: 2vmax;
}

.workImgContainer {
  padding-top: 1vmax;
  grid-column: 2;
  display: flex;
  flex-direction: column;
}

.workImgWideContainer {
  grid-column: 1 / span 2;
}

.workImgWide {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.workImgWideRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 2vmax;
  overflow-x: scroll;
  height: fit-content;
  scrollbar-width: none;
}

.workImgWideRow img {
  flex: 1;
  /* max-width: 50%; */
}
.workImgWideRow video {
  flex: 1;
  /* max-width: 50%; */
}

.videoAbout{
  position: absolute;
  right: -10vmax;
  bottom: 3vmax;
  width: 55%;
  z-index: -1;
  transform: rotate(-70deg);
}

.workParagraph {
  padding: 1vmax;
}

.workFooter {
  padding: 1vmax;
}

.workFooters {
  font-size: 1rem;
  /* text-align: justify; */
  padding-top: 1vmax;
  white-space: pre-line;
}

.workLinks {
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  padding: 1vmax;
}

.mainVideoVertical {
  max-width: 50%;
  max-height: 91vh;
  height: auto;
}

.mainImgLong{
  max-width: 100%;
  height: auto;
}

.bioLinks{
  text-decoration: underline;
  cursor: pointer;
}

.textBold{
  font-weight: bold;
}

.textSup{
  vertical-align: super;
  font-size:small;
}



@media screen and (max-width: 768px) {

  .container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 2rem auto;
    margin-left: 1vmax;
    margin-right: 1vmax;
    margin-bottom: 1.85vmax;
    margin-top: 2vmax;
/* margin: 0.5rem; */
  }



  .workTextContainer {
    grid-column: 1 / span 2;
  }
  .writingTextContainer{
    grid-column: 1 / span 2;
  }
  .workImgContainer {
    grid-column: 1 / span 2;
  }

  .workImgWideRow {
    flex-direction: column;
  }
  
  .workImgWideRow img {
    max-width: 100%;
  }
  .workImgWideRow video {
    max-width: 100%;
  }
  .workImgMain {
    flex-direction: column;
  }
  .title {
    font-size: 1.2rem;
  }

  .thumbnailsHeaderMobile{
    display: flex;
    justify-content:space-between;
    font-size: 1.6rem;
    /* font-weight: bold; */
    /* text-decoration: underline; */
    /* color: green; */
  }
  
  
}