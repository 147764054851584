.description {
  /* text-align: justify; */
  font-size: 1.2rem;
  padding: 0.5rem;
}

.workLinks {
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  width: fit-content;
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.imgAbout {
  margin-top: 1vmax;
  max-width: 100%;
}

.credits {
  /* text-align: justify; */
  font-size: 1rem;
}

.overflow {
  overflow: auto;
}

.aboutContainer{
  height: 100px;
}

.margin{
  margin: 2vmax;
  margin-top: 1vmax;
  display: grid;
  grid-template-columns: 60% auto;
}

@media screen and (max-width: 768px) {
  .overflow {
    grid-column: 1 / span 2;
  }

}