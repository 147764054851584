#name {
  position: absolute;
  bottom: 0%;
  left: 0;
  z-index: 1;
  font-size: 8rem;
  margin: 1vmax;
  cursor: default;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.container {
  color: white;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

@media screen and (max-width: 768px) {
  #name {
    font-size: 20vw;
  }
  
}